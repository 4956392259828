import type { DomainType } from "~~/stores/organization.store"

export enum DefaultListValueType {
  DEFAULT_ACTION_DESCRIPTION = "DEFAULT_ACTION_DESCRIPTION",
  DEFAULT_INSPECTION_DESCRIPTION = "DEFAULT_INSPECTION_DESCRIPTION",
  DEFAULT_MALFUNCTION_DESCRIPTION = "DEFAULT_MALFUNCTION_DESCRIPTION",
  DEFAULT_ARTICLE_CATEGORY = "DEFAULT_ARTICLE_CATEGORY",
  DEFAULT_INVOICE_PERIOD = "DEFAULT_INVOICE_PERIOD",
}

export type DefaultListValue = Omit<ListValue, "organization" | "type"> & {
  type: DefaultListValueType
  domain: DomainType
}

const createListStore = (type: DefaultListValueType) =>
  defineStore(`list:${type}`, () => {
    /** @deprecated */
    const items = ref<Array<DefaultListValue>>([])

    const getItems = async (pagination = false, domain: DomainType) =>
      useCrudMethods<DefaultListValue>("/default-list-values", items).readItems({ query: JSON.stringify({ type, domain }), pagination })
    const getItemsById = async (id: DefaultListValue["_id"]) => useCrudMethods<DefaultListValue>("/default-list-values", items).readItemById(id)
    const updateItem = async (id: DefaultListValue["_id"], item: Partial<DefaultListValue>) =>
      useCrudMethods<DefaultListValue>("/default-list-values", items).updateItem(id, item)
    const postItem = async (item: Partial<DefaultListValue>) => useCrudMethods<DefaultListValue>("/default-list-values", items).createItem(item)
    const deleteItem = async (id: DefaultListValue["_id"]) => useCrudMethods<DefaultListValue>("/default-list-values", items).deleteItem(id)

    return {
      items,
      getItems,
      getItemsById,
      updateItem,
      postItem,
      deleteItem,
    }
  })

export const useDefaultActionDescriptionStore = createListStore(DefaultListValueType.DEFAULT_ACTION_DESCRIPTION)
export const useDefaultInspectionDescriptionStore = createListStore(DefaultListValueType.DEFAULT_INSPECTION_DESCRIPTION)
export const useDefaultMalfunctionDescriptionStore = createListStore(DefaultListValueType.DEFAULT_MALFUNCTION_DESCRIPTION)
export const useDefaultArticleCategoryStore = createListStore(DefaultListValueType.DEFAULT_ARTICLE_CATEGORY)
export const useDefaultInvoicePeriodStore = createListStore(DefaultListValueType.DEFAULT_INVOICE_PERIOD)